import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import background from "../../images/tokyo_skyline.jpg";

const Header = () => {
  
  const { hero } = useContext(PortfolioContext);
  const { img, title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron" style={{ backgroundImage: `url(${background})` }}>
      <Container >
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={50} distance="30px">
          <h1 className="hero-title">
            {"Hi, i'm"}{' '}
            <span className="text-color-main">{'Corwin'}</span>
            <br />
            {subtitle || ""}
          </h1>
        </Fade>
        {/* <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta}
              </Link>
            </span>
          </p>
        </Fade> */}
      </Container>
    </section>
  );
};

export default Header;
